<template>
    <FormattedStaticPage :data="data"/>
</template>

<script>
import FormattedStaticPage from "@/Components/Base/FormattedStaticPage.vue";

export default {
    components: {FormattedStaticPage},
    data() {
        const siteUrl = document.location.origin

        return {
            data: [
                {
                    title: '1. Загальні положення та сфера застосування',
                    items: [
                        '1.1. Це Положення про обробку та захист персональних даних (надалі – «Положення») розроблене ФОП Сірик Микола Іванович (ідентифікаційний код  3525214956) (надалі – «Володілець персональних даних»), у відповідності до чинного законодавства України, в тому числі, але не виключно, Закону України «Про захист персональних даних» від 01 червня 2010 року № 2297-VI з урахуванням принципів та правил Регламента General Data Protection Regulation (GDPR) Європейського Союзу щодо Користувачів Онлайн платформи CupCult та встановлює порядок отримання, збору, накопичення, зберігання, обробки, використання, забезпечення захисту і розкриття персональних даних Користувачів (надалі – «Дані» та/або «Персональні дані») за допомогою веб-сайту: https://cupcult.co/  (надалі – «Сайт») та/або пов\'язаних з ним послуг та інструментів.',
                        '1.2. Здійснивши реєстрацію на Сайті, та починаючи використання Сайту, або при спробі оформити замовлення без попередньої реєстрації, Користувач надає дозвіл та однозначну згоду на обробку його персональних даних на умовах та в порядку, що викладені нижче, а також підтверджує ознайомлення з цим Положенням, його прийняття та згоду з його змістом.',
                        '1.3. Володілець персональних даних надає Користувачам змогу швидко та просто здійснювати оплату й водночас гарантує надійне збереження їхніх даних завдяки багаторівневому  захисту та використання технології токенізації платіжних карток за допомогою системи шифрування даних, що дозволяє здійснювати платежі, не розголошуючи дані платіжної картки. Токени захищають персональну інформацію та фінансові операції передаючи дані платіжної картки у зашифрованому вигляді;  створюються через математичні формули або випадкові буквено-цифрові генератори і не можуть бути використані зловмисниками, так як не несуть в собі ніякої цінності.',
                        '1.4. Користувач, реєструючи платіжну карту на Сайті надсилає запит на присвоєння токена, який використовуватиметься замість реквізитів доданої платіжної картки. Банк-еквайр, що виконує переказ та банк-емітент платіжної картки використовують наявну інформацію про держателя платіжної картки та його дешифровану платіжну інформацію для здійснення переказу.',
                        '1.5. У цьому Положенні всі терміни використовуються в значенні, визначеному у Законі України «Про захист персональних даних» від 01 червня 2010 року № 2297-VI.',
                        '1.6. На Сайті можуть бути посилання на інші веб-сайти (виключно в інформаційних цілях). При переході за посиланням на інші веб-сайти дія цього Положення на такі сайти не поширюватиметься. У зв\'язку з чим, Володілець персональних даних рекомендує ознайомитися з політикою в сфері конфіденційності та персональних даних кожного сайту перед тим, як передавати персональні дані, за якими Ви можете бути ідентифіковані.',
                        '1.7. Користувач визнає, що у випадку його недбалого ставлення до безпеки і захисту своїх Даних і даних по авторизації треті особи можуть отримати несанкціонований доступ до облікового запису і Даних Користувача. Володілець персональних даних не несе відповідальності за збитки, завдані таким Доступом.',
                    ]
                },
                {
                    title: '2. Склад та зміст персональних даних',
                    items: [
                        '2.1. Під Даними розуміється будь-яка інформація, що прямо чи опосередковано відноситься до конкретного Користувача.',
                        'Це може бути: ім’я, прізвище, по-батькові (у разі наявності), номер телефона, адреса електронної пошти, дата народження, мова спілкування, адреса місця проживання/перебування/доставки, інформація про дії Користувача під час використання Сайту, IP-адреса, дані про пристрої, що використовуються Користувачем (тип пристрою, тип браузера, операційна система пристрою), історія повідомлень (інформація, яка міститься в переписці між Користувачем з адміністрацією Сайту або з Продавцями), історія відгуків чи коментарів, інша інформація, за допомогою якої здійснюється комунікація, та яка за бажанням Користувача надається ним в реєстраційній формі та/або при заповненні власного профілю на Сайті, при проходженні опитування (шляхом заповнення анкети або в інший спосіб), або інформація отримана при усній комунікації Користувача та адміністрації Сайту, інформація, яка надається при здійсненні оплати на Сайті.',
                        'Даний перелік персональних даних не є сталим та обов’язковим для всіх Користувачів, а залежить від потреб та бажань самого Користувача та від тих операцій, які він/вона виконує на Сайті.',
                        '2.2. Під Даними також розуміється інша інформація, отримана Сайтом на законних підставах від третіх осіб та/або доступна з профайлів Користувача у соціальних мережах – у випадку реєстрації на Сайті за допомогою служб автентифікації соціальних мереж. В такому випадку Користувач надає свою згоду на обробку інформації, що доступна із відповідних облікових записів (профайлів) у соціальних мережах.',
                        '2.3. Користувачі несуть відповідальність за всю інформацію, що розміщується ними в загальнодоступних облікових записах. Користувач повинен усвідомити всі ризики, пов\'язані з тим, що він оприлюднює адресу або інформацію про точне місце свого розташування. Якщо Користувач вирішив увійти на Сайт, використовуючи службу автентифікації стороннього оператора, наприклад інформацію Facebook, Сайт може отримати додатковий профіль, або іншу інформацію, доступ до якої надано такою третьою особою.',
                        '2.4. На сайті використовується Microsoft Clarity та Microsoft Advertising, щоб фіксувати те, як користувач використовує Сайт і взаємодієте з ним за допомогою показників поведінки, теплових карт і відтворення сеансу, щоб покращувати та рекламувати продукти/послуги. Дані про використання Сайту збираються за допомогою перших і сторонніх файлів cookie та інших технологій відстеження для визначення популярності продуктів/послуг і онлайн-активності. Крім того, адміністрація Сайту використовує цю інформацію для оптимізації Сайту, безпеки та реклами. Щоб дізнатися більше про те, як корпорація Майкрософт збирає та використовує дані користувачів, перегляньте <a href="https://privacy.microsoft.com/privacystatement" target="_blank" class="underline">Декларацію корпорації Майкрософт про конфіденційність.</a>'
                    ]
                },
                {
                    title: '3. Мета, межі, підстави та строки обробки персональних даних',
                    items: [
                        '3.1. Під обробкою Даних розуміється будь-які дії з Даними в тому числі, але не виключно: доступ, надання, розповсюдження, збір, систематизація, зберігання, накопичення, запис, передача, блокування, видалення, уточнення, оновлення та/або зміна, знеособлення та інші способи використання Даних.',
                        '3.2. Метою обробки персональних даних є:',
                        '3.2.1. Ідентифікація Користувача, зареєстрованого на Сайті, для оформлення замовлення і (або) укладення Договору купівлі-продажу товару дистанційним способом з Продавцем товару;',
                        '3.2.2. Надання Користувачу доступу до персоналізованих ресурсів Сайту;',
                        '3.2.3. Встановлення зворотного зв\'язку з Користувачем, включаючи направлення повідомлень, запитів, що стосуються використання Сайту, надання послуг, обробка запитів і заявок від Користувача;',
                        '3.2.4. Визначення місця знаходження Користувача для забезпечення безпеки, запобігання шахрайства;',
                        '3.2.5. Підтвердження достовірності та повноти персональних даних, наданих Користувачем;',
                        '3.2.6. Створення облікового запису для здійснення покупок, якщо Користувач дав згоду на створення облікового запису;',
                        '3.2.7. Повідомлення Користувача Сайту про стан Замовлення;',
                        '3.2.8. Обробки та отримання платежів, оскарження платежів;',
                        '3.2.9. Надання Користувачеві ефективної клієнтської та технічної підтримки при виникненні проблем, пов\'язаних з використанням Сайту;',
                        '3.2.10. Надання Користувачеві з його згоди оновлень продукції, спеціальних пропозицій, інформації про ціни, новинних розсилок та інших відомостей від імені Сайту або від імені Продавців;',
                        '3.2.11. Здійснення рекламної діяльності з згоди Користувача;',
                        '3.2.12. Виконання інших обов\'язків, покладених законом на Володільця персональних даних.',
                        '3.3. Підставою для обробки персональних даних Користувачів Сайту є згода Користувача на обробку його персональних даних, яка виражається у користуванні Сайтом.',
                        '3.4. Володілець персональних даних не здійснює обробку даних, які стосуються расової, національної приналежності, політичних поглядів, релігійних та інших переконань, членства в громадських організаціях. Інформація, яка характеризує фізіологічні особливості Користувачів, на основі яких можна встановити їх особистість не обробляються також.',
                        '3.5. Обробка персональних даних Користувача здійснюється без обмеження терміну, будь-яким законним способом, в тому числі в інформаційних системах персональних даних з використанням засобів автоматизації або без використання таких засобів. Строки обробки та зберігання Даних визначаються виходячи із цілей обробки Даних, а також виходячи із умов, вказаних в договорах, укладених з Користувачами у відповідності до вимог діючого законодавства України. Персональні дані обробляються та зберігаються настільки довго, наскільки це необхідно для досягнення мети обробки, визначеної в п.3.2. цього Положення.',
                        'При цьому, Користувач наділений правом подати запит про припинення обробки та зберігання власних персональних даних або видалити власні дані з особистого кабінету Користувача. Видалення облікового запису Користувача або надходження відповідної заяви про припинення обробки та зберігання персональних даних Користувача є підставою для негайного припинення обробки та зберігання таких Даних Володільцем персональних даних з подальшим їх видаленням.',
                        '3.6. Користувачі в будь-який момент можуть змінити/видалити особисту інформацію або відмовитися від розсилки повідомлень, або відкликати згоду на обробку Даних. Це можна зробити у своєму Особистому кабінеті або, шляхом направлення повідомлення на електронну адресу: cupcult.official@gmail.com з поміткою в темі листа «Персональні дані». Видалення облікового запису (аккаунту) за ініціативою Користувача відбувається в порядку, визначеному в Угоді користувача.',
                        '3.7. У випадку відсутності активності у обліковому записі Користувача протягом строку, що перевищує 5 (п‘ять) років, Володілець персональних даних залишає за собою право видалити обліковий запис Користувача, включаючи всі особисті дані, що зберігаються в обліковому записі, що означає, що Користувач більше не зможете отримати до нього доступ і використовувати його.',
                        '3.8. За наявності запитань щодо підстав, умов, процедур зміни чи видалення персональних даних Користувачів просимо контактувати на офіційну скриньку Сайту  cupcult.official@gmail.com із поміткою в темі листа «Персональні дані».',
                    ]
                },
                {
                    title: '4. Місцезнаходження персональних даних',
                    items: [
                        '4.1. Персональні дані Користувачів Сайту обробляються та захищаються на захищених серверах Володільця персональних даних.',
                    ]
                },
                {
                    title: '5. Умови розкриття інформації про персональні дані третім особам',
                    items: [
                        '5.1. Розкриття Даних відбувається при замовленні Користувачем товарів на Сайті Продавцям таких товарів в об’ємі необхідному для ідентифікації Користувача, виконання та опрацювання замовлення Користувача.',
                        '5.2. Володілець персональних даних здійснює обробку Даних на законній та справедливій основі:',
                        'Дані не розкриваються третім особам і не розповсюджуються без згоди Користувача, за виключенням випадків передбачених діючим законодавством України і лише в інтересах національної безпеки, економічного добробуту і прав людини, зокрема, але не виключно:',
                        '- за обґрунтованими запитами органів державної влади, які мають право витребувати та отримувати такі Дані;',
                        '- для цілей боротьби з шахрайством та зловживаннями на Сайті.',
                        '5.3. У випадках передачі персональних даних, передбачених розділом 5 цього Положення, інформування Користувача про передачу його персональних даних залишається на розсуд Володільця персональних даних.',
                    ]
                },
                {
                    title: '6. Захист персональних даних',
                    items: [
                        '6.1. Володільця персональних даних обладнано системними і програмно-технічними засобами та засобами зв’язку, які запобігають втратам, крадіжкам, несанкціонованому знищенню, викривленню, підробленню, копіюванню інформації і відповідають вимогам міжнародних та національних стандартів.',
                        '6.2. Працівники/уповноважені особи, які безпосередньо здійснюють обробку та/або мають доступ до персональних даних у зв’язку з виконанням своїх службових (трудових) обов’язків зобов’язані дотримуватись вимог законодавства України в сфері захисту персональних даних та внутрішніх документів, щодо обробки і захисту персональних даних.',
                        '6.3. Працівники/уповноважені особи, що мають доступ до персональних даних, у тому числі, здійснюють їх обробку зобов’язані не допускати розголошення у будь-який спосіб персональних даних, які їм було довірено або які стали відомі у зв’язку з виконанням професійних чи службових або трудових обов’язків. Таке зобов’язання чинне після припинення ними діяльності, пов’язаної з персональними даними, крім випадків, установлених законом.',
                        '6.4. Персональні дані не повинні зберігатися довше, ніж це необхідно для мети, для якої такі дані зберігаються, але у будь-якому разі не довше строку зберігання даних, визначеного цим Положенням за згодою Користувача.',
                        '6.5. Для цілей захисту облікових записів Користувачів та їх персональних даних від неправомірного доступу, використовується додатково двофакторна аутентифікація, що є додатковим рівнем захисту облікового запису користувача.',
                        '6.6. Під час авторизації Користувача з невідомого пристрою спрацьовує двофакторна аутентифікація. Тож, окрім введення паролю, потрібно також ввести одноразовий 6-ти значний код, який надходить на телефон Користувача у вигляді SMS або Viber повідомлення або відбувається додаткова перевірка довірених пристроїв (ідентифікатор верифікованого пристрою/програми). У тому ж повідомленні міститься інформація щодо параметрів входу у його/її аккаунт: дата спроби авторизації, IP пристрою, тип клієнтського браузера.',
                        '6.7. У деяких випадках, наприклад, якщо Користувач виконав занадто багато невдалих спроб авторизації, спрацьовує додатковий алгоритм захисту - ввід Captcha (для перевірки, чи не є користувач роботом).',
                        '6.8. Володільцем персональних даних розроблено та впроваджено внутрішні правила стосовно роботи із персональним даними, що включає процедуру видалення ряду даних після видалення особистого кабінету Користувача, рівні доступу внутрішніх співробітників до даних Користувачів, безпечний порядок обміну такими даними. Володілець персональних даних на постійній основі проводить аудит своїх систем безпеки з метою виявлення можливостей для покращення безпечного зберігання та використання даних Користувачів. Також Володілець персональних даних дотримується вимог Типового порядку обробки персональних даних, що затверджений наказом Верховної Ради України 08.01.2014 № 1/02-14 в тій мірі, в якій це стосується саме персональних даних.',
                    ]
                },
                {
                    title: '7. Права Користувача (суб’єкта персональних даних)',
                    items: [
                        '7.1. Суб’єкт персональних даних (Користувач) має право:',
                        '7.1.1. знати про місцезнаходження персональних даних, що містить його персональні дані, його призначення та найменування, місцезнаходження та / або місце проживання (перебування) володільця чи розпорядника або дати відповідне доручення щодо отримання цієї інформації уповноваженим ним особам, крім випадків, встановлених законом;',
                        '7.1.2. отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх осіб, яким передаються його персональні дані;',
                        '7.1.3. на доступ до своїх персональних даних;',
                        '7.1.4. керувати процедурою направлення повідомлень через відповідні налаштування на Сайті;',
                        '7.1.5. отримувати не пізніш як за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи зберігаються його персональні дані, а також отримувати зміст його персональних даних, які зберігаються;',
                        '7.1.6. пред’являти вмотивовану вимогу із запереченням проти обробки своїх персональних даних органами державної влади, органами місцевого самоврядування при здійсненні їхніх повноважень, передбачених законом;',
                        '7.1.7. на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв’язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;',
                        '7.1.8. звертатися з питань захисту своїх прав щодо персональних даних до органів державної влади, органів місцевого самоврядування, до повноважень яких належить здійснення захисту персональних даних;',
                        '7.1.9. застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;',
                        '7.1.10. право подати заяву щодо припинення обробки власних персональних даних із проханням знищення вказаних даних.',
                    ]
                },
                {
                    title: '8. Порядок роботи з запитами суб’єкта персональних даних (Користувача)',
                    items: [
                        '8.1. Суб’єкт персональних даних (Користувач) має право на одержання будь-яких відомостей про себе у будь-якого суб’єкта відносин, пов’язаних з персональними даними, без зазначення мети запиту, крім випадків, установлених законом.',
                        '8.2. Доступ суб’єкта персональних даних (Користувача) до даних про себе здійснюється безоплатно.',
                        '8.3. Суб’єкт персональних даних (Користувач) подає запит щодо доступу (далі — запит) до персональних даних володільцю персональних даних.',
                        'У запиті зазначаються:',
                        '1) прізвище, ім’я та по батькові, місце проживання (місце перебування) і реквізити документа, що посвідчує особу суб’єкта персональних даних (Користувача);',
                        '2) інші відомості, що дають змогу ідентифікувати особу суб’єкта персональних даних (Користувача);',
                        '3) відомості про персональні дані, стосовно яких подається запит, чи відомості про володільця чи розпорядника;',
                        '4) перелік персональних даних, що запитуються.',
                        '8.4. Строк вивчення запиту на предмет його задоволення не може перевищувати десяти робочих днів з дня його надходження.',
                        '8.5. Протягом цього строку Володілець персональних даних доводить до відома суб’єкта персональних даних (Користувача), що запит буде задоволене або відповідні персональні дані не підлягають наданню, із зазначенням підстави, визначеної у відповідному нормативно-правовому акті.',
                        '8.6. Запит задовольняється протягом тридцяти календарних днів з дня його надходження, якщо інше не передбачено законом.',
                    ]
                },
                {
                    title: '9. Видалення персональних даних Користувача',
                    items: [
                        '9.1. Видалення персональних даних Користувача може здійснюватись за бажанням Користувача, через здійснення ним наступних дій:',
                        '- подання письмового запиту з проханням припинити обробку персональних даних на електронну пошту cupcult.official@gmail.com з поміткою в темі листа «Персональні дані»;',
                        '- вчинення дій із видалення даних Користувача із Особистого кабінету Користувача, який розміщений на Сайті.',
                        '9.2. Персональні дані Користувачів видаляються або знищуються в порядку, встановленому відповідно до вимог Закону.',
                        'Окрім випадків, передбачених п.9.1. Персональні дані Користувача також підлягають видаленню у разі:',
                        '- видання відповідного припису Уповноваженого Верховної Ради України з прав людини (далі - Уповноважений) або визначених ним посадових осіб секретаріату Уповноваженого Верховної Ради України з прав людини;',
                        '- набрання законної сили рішенням суду щодо видалення або знищення персональних даних.',
                        '9.3. Користувачі в будь-який момент можуть відкликати згоду на обробку Персональних даних. Це можна зробити шляхом направлення запиту на електронну адресу: cupcult.official@gmail.com з поміткою в темі листа «Персональні дані».',
                        '9.4. У випадку, коли Користувач здійснює дії із самостійного видалення Персональних даних із Особистого кабінету Користувача, знищення персональних даних Користувача із інформаційних систем здійснюється автоматично.',
                        '9.5. Знищення персональних даних Користувачів здійснюється у спосіб, що виключає подальшу можливість поновлення таких персональних даних, а також ідентифікацію Користувача.',
                    ]
                },
                {
                    title: '10. Внесення змін до Положення',
                    items: [
                        '10.1. Володілець персональних даних може час від часу в односторонньому порядку оновлювати це Положення без повідомлення Користувача про такі зміни. Нова редакція Положення вступає в силу з моменту його розміщення на Сайті, якщо інше не передбачено новою редакцією Положення. Чинна редакція Положення завжди знаходиться на сторінці за адресою: https://cupcult.co/.',
                        '10.2. У разі якщо були внесені будь-які зміни в Положення, з якими Користувач не згоден, він зобов\'язаний припинити використання Сайту. Факт продовження використання Сайту є підтвердженням згоди і прийняття Користувачем відповідної редакції Положення.',
                    ]
                }
            ]
        }
    }
}
</script>
